import { Agent } from "../types/agent";

export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export type ChatAppRequestOverrides = {
    retrieval_mode?: RetrievalMode;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    top?: number;
    temperature?: number;
    prompt_template?: string;
    system_prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    search_index?: string;
    locked_files?: string[];
    chatsession_id?: string;
    temporary_chat?: boolean;
    thread_id?: string;
};

export type ResponseMessage = {
    content: string;
    role: string;
    image?: string[];
};

export type ResponseContext = {
    thoughts: string | null;
    data_points: string[];
    followup_questions: string[] | null;
};

export type ResponseChoice = {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    session_state: any;
};

export type ChatAppResponseOrError = {
    choices?: ResponseChoice[];
    error?: string;
};

export type ChatAppResponse = {
    choices: ResponseChoice[];
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
};

export type ChatAppRequest = {
    messages: ResponseMessage[];
    context?: ChatAppRequestContext;
    stream?: boolean;
    session_state: any;
    agent_config?: Agent;
};

export type chatData = {
    agent_id: string;
    chatsession_id: string;
    choices: ResponseChoice[];
    query: string;
    system_prompt_template: string;
    timestamp: string;
    topic: string;
    thread_id?: string;
};

export type chatConversation = {
    choices: ChatAppResponse[];
    query: string;
};

export type conversation = {
    answer: string;
    document_index: null;
    document_names: string;
    query: string;
    timestamp: Date;
    context: string;
    image?: string;
};
