import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

i18n.use(LanguageDetector).init({
    supportedLngs: ["en-US", "fi-FI"]
});

i18n.use(initReactI18next)
    .use(backend)
    .init({
        debug: true,
        ns: ["translation"],
        fallbackLng: { en: ["en-US"], default: ["en-US"] },
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ["cookie"],
            caches: ["cookie"]
        },
        load: "currentOnly"
    });

export default i18n;
