import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter, createHashRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { msalConfig, useLogin } from "./authConfig";
import "./index.css";
import "./assets/scss/global.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import Layout from "./pages/layout/Layout";
import { Frontpage } from "./pages/frontpage/Frontpage";
import { DarkModeProvider } from "./DarkModeContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useTranslation, Trans } from "react-i18next";

var layout;
if (useLogin) {
    var msalInstance = new PublicClientApplication(msalConfig);

    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getActiveAccount());
    }

    // Listen for sign-in event and set active account
    msalInstance.addEventCallback(event => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const account = event.payload as AccountInfo;
            msalInstance.setActiveAccount(account);
        }
    });
    layout = (
        <I18nextProvider i18n={i18n}>
            <DarkModeProvider>
                <MsalProvider instance={msalInstance}>
                    <Layout />
                </MsalProvider>
            </DarkModeProvider>
        </I18nextProvider>
    );
} else {
    layout = <Layout />;
}

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            {
                path: "", // Set the default path to load the Frontpage
                element: <Frontpage />
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
