import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useLogin } from "../../authConfig";
import { LoginButton } from "../../components/LoginButton";
import { ThemeToggler } from "../../components/ThemeToggler";
import { useMsal } from "@azure/msal-react";
import { useDarkMode } from "../../DarkModeContext";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import i18n from "../../i18n";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

const Layout = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [currentLang, setcurrentLang] = useState<string>(i18n.language);

    const t = useGlobalTranslation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { darkMode } = useDarkMode();
    useEffect(() => {
        if (darkMode) {
            document.body.classList.add("darkMode");
        } else {
            document.body.classList.remove("darkMode");
        }
    }, [darkMode]);

    const langOptions = [
        {
            key: "fi",
            text: t("language.fi"),
            selected: currentLang == "fi-FI",
            data: "fi-FI"
        },
        {
            key: "en",
            text: t("language.en"),
            selected: currentLang == "en-US",
            data: "en-US"
        }
    ];

    const onLangChange = (_ev: React.FormEvent<HTMLDivElement> | undefined, option?: IDropdownOption<string> | undefined) => {
        setcurrentLang(option?.data || "en-US");
        i18n.changeLanguage(option?.data);
        window.location.reload(); // todo make it work without reload
    };

    const dropdownStyles: Partial<IDropdownStyles> = {
        // caretDownWrapper: { background: "red" }
    };

    if (activeAccount) {
        return (
            <div className={styles.layout}>
                <header className={[darkMode ? styles.headerDark : styles.header, "navbar navbar-expand-lg bd-navbar sticky-top"].join(" ")} role={"banner"}>
                    <div className={[styles.headerContainer, "row col-12"].join(" ")}>
                        <div className="row justify-content-between ms-2 col-4">
                            <Link to="" className={[darkMode ? styles.headerTitleContainerDark : styles.headerTitleContainer, "row col"].join(" ")}>
                                <div
                                    title="Innofactor logo"
                                    className={[darkMode ? styles.logoDark : styles.logoLight, styles.logo, "pe-0 w-auto"].join(" ")}
                                ></div>
                            </Link>
                        </div>

                        <div className="d-none d-lg-flex col-2 flex-row-reverse">{useLogin && !activeAccount && <LoginButton darkMode={darkMode} />}</div>

                        {/* TODO burger button and dropdown modal under it when clicked */}

                        <Button className={[styles.btnburger, "", "d-flex justify-content-end col-1"].join(" ")} onClick={handleShow}>
                            <i style={!darkMode ? { color: "black" } : {}} className={[styles.bi, "bi bi-three-dots"].join(" ")} />
                        </Button>
                    </div>
                    <Offcanvas show={show} placement="end" onHide={handleClose} className={darkMode ? styles.dark : styles.light}>
                        <Offcanvas.Header>
                            <Offcanvas.Title>{t("mobileNav.title")}</Offcanvas.Title>
                            <i
                                role="button"
                                onClick={handleClose}
                                className={[!darkMode ? styles.buttonClose : "", "bi bi-x-lg ms-auto me-1 fs-3"].join(" ")}
                            />
                        </Offcanvas.Header>
                        <Offcanvas.Body className="d-flex flex-column ">
                            <hr />
                            <div className="d-flex">
                                <span className="me-3">{t("theme.title")}</span>
                                {<ThemeToggler darkMode={darkMode} show={true} />}
                            </div>
                            <hr />
                            <label>{t("language.title")}</label>
                            <div className={[darkMode ? styles.langButtonsDark : styles.langButtons, "d-flex flex-column"].join(" ")}>
                                {langOptions.map((option, index) => (
                                    <button
                                        key={index}
                                        className={[
                                            "btn mt-2 mb-2",
                                            darkMode ? styles.langButtonDark : styles.langButton,
                                            currentLang === option.data ? (darkMode ? styles.activeLangButtonDark : styles.activeLangButton) : ""
                                        ].join(" ")}
                                        onClick={() => onLangChange(undefined, option)}
                                    >
                                        {option.text}
                                    </button>
                                ))}
                            </div>
                            <hr />
                            <div className="d-flex h-100 flex-column justify-content-end">{useLogin && <LoginButton darkMode={darkMode} />}</div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </header>
                <Outlet />
            </div>
        );
    } else {
        return <Outlet />;
    }
};

export default Layout;
