import { useTranslation } from "react-i18next";

function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts?.pop()?.split(";")?.shift();
    }
}

export function useGlobalTranslation() {
    const lngs = {
        "en-US": { nativeName: "English" },
        "fi-FI": { nativeName: "Suomi" }
    };

    const selectedLanguage = getCookie("i18next") || "en-US"; // Default to 'en-US' if the cookie is not set
    const { t, i18n } = useTranslation();

    if (i18n.language !== selectedLanguage) {
        i18n.changeLanguage(selectedLanguage);
    }

    return t;
}

export const currentLanguage = getCookie("i18next") || "en-US";
