import { useEffect, useRef, useState } from "react";
import { Agent } from "../../types/agent";
import Chat from "../chat/Chat";
import styles from "./Agents.module.scss";
import Carousel from "react-bootstrap/Carousel";
import useWindowDimensions from "../../services/viewportService";
import { useDarkMode } from "../../DarkModeContext";
import { getToken, useLogin, getUserGroups } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { chatData, chatConversation, deleteChatHistoryBySessionId, getChatconversationBySessionId, getChatHistoryOfUser, ChatAppResponse } from "../../api";
import { AuthenticationResult } from "@azure/msal-browser";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";
import { LoginButton } from "../../components/LoginButton";

const Agents = () => {
    const t = useGlobalTranslation();
    const { width } = useWindowDimensions();
    const [columnPlacementIndex, setColumnPlacementIndex] = useState<number>(0);
    const [columnId, setColumnId] = useState<number>(3);
    const agentRootRef = useRef<HTMLDivElement | null>(null);
    const agentsRef = useRef<HTMLDivElement | null>(null);
    const { darkMode } = useDarkMode();
    const [chatHistory, setChatHistory] = useState<chatData[]>([]);
    const [token, setToken] = useState<string>("");
    const [deleting, setDeleting] = useState<number | null>(null);
    const [collapsed, setCollapsed] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [userGroups, setUserGroups] = useState<string[]>([]);

    const [chatColumns, setChatColumns] = useState<
        { number: number; id: number; history?: chatConversation[]; systemPromt?: string; sessionId?: string; threadId?: string }[]
    >([
        {
            number: 0,
            id: 0,
            history: [],
            systemPromt: "",
            sessionId: "",
            threadId: ""
        }
    ]);
    const agents: Agent[] = [
        {
            type: "Basic",
            name: t("agent.basic.name"),
            apiEndpoint: "/secure_chat",
            description: t("agent.basic.description"),
            model: "gpt-4o",
            id: "8f5576d9-ceeb-4e3e-a4a6-8be858ca8dcc",
            permissionGroup: ""
        },
        {
            type: "FileReader",
            name: t("agent.fileReader.name"),
            apiEndpoint: "/chat",
            searchIndex: "user-document-index-2",
            description: t("agent.fileReader.description"),
            model: "gpt-4o",
            id: "18264712-7652-42ed-912b-a0dad229592b",
            permissionGroup: ""
        },
        {
            id: "7fb7324d-ba98-45af-8c72-59e6aa4753ab",
            type: "Azure",
            name: t("agent.fileReader2.name"),
            apiEndpoint: "/chat",
            searchIndex: "gptkbindex",
            description: t("agent.fileReader2.description"),
            model: "gpt-4o",
            permissionGroup: ""
        },
        {
            id: "1ta7324d-ba98-45af-6f52-59e6ae7773db",
            type: "SQL",
            name: t("agent.sql.name"),
            apiEndpoint: "/sql_assistant_chat",
            description: t("agent.sql.description"),
            model: "gpt-4o",
            permissionGroup: "6e1cf51a-ab04-4de7-bcb5-10f7df8366f6"
        },
        {
            id: "4dd3124d-cd65-67gd-6f52-44t3aft873fc",
            type: "Jira",
            name: t("agent.jira.name"),
            apiEndpoint: "/sql_assistant_chat",
            description: t("agent.jira.description"),
            searchIndex: "jiradocs-index",
            model: "gpt-4o",
            permissionGroup: "3c6ceb13-16bb-4ca8-97a1-759a3543c77c"
        }
    ];

    const client = useLogin ? useMsal().instance : undefined;
    useEffect(() => {
        const fetchUserGroups = async () => {
            const groups = await getUserGroups(client);
            setUserGroups(groups ?? []);
        };
        fetchUserGroups();
    }, []);

    const deleteHistory = async (e: any, sessionId: string, index: number | null, threadId: string | null) => {
        setDeleting(index);
        e.stopPropagation();

        if (token) {
            try {
                const response = await deleteChatHistoryBySessionId(token, sessionId, threadId);
                //DEBUG  console.log("DELETE:", response);
                if (response == true) {
                    await fetchChatHistory();
                    setDeleting(null);
                }
            } catch (error) {
                console.error("Error deleting chat history:", error);
            }
        }
    };

    const handleAdd = async (index: number, chatConversation?: chatConversation[], agentId?: string, promt?: string, sessionId?: string, threadId?: string) => {
        setColumnPlacementIndex(index);
        setColumnId(columnId + 1);
        const newID = columnId + 1;

        let newColumn = {
            number: newID <= 2 ? newID : 0,
            id: newID
        };

        let newColumn2 = {
            number: getAgentIndex(agentId!) ?? 0,
            id: newID,
            history: chatConversation,
            systemPromt: promt ?? "",
            sessionId: sessionId ?? "",
            threadId: threadId ?? ""
        };

        setChatColumns(prevChatColumns => {
            const updatedColumns = [...prevChatColumns];
            if (chatConversation) {
                updatedColumns.splice(index, 0, newColumn2);
            } else {
                updatedColumns.splice(index, 0, newColumn);
            }
            return updatedColumns;
        });

        if (agentsRef.current) {
            const addedAgentElement = agentsRef.current.children[index];
            const isLast = index === agentsRef.current.children.length;

            if (isLast) {
                setTimeout(() => {
                    const addedAgentElement = agentsRef.current?.children[index];
                    addedAgentElement?.scrollIntoView({ behavior: "smooth", block: "end" });
                }, 500);
            } else {
                addedAgentElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }
    };

    const initToken = async () => {
        if (client) {
            try {
                await client.initialize();
                const tokenValue = await getToken(client);
                setToken(tokenValue?.accessToken ?? "");
            } catch (error) {
                console.log("Error in initToken:", error);
            }
        }
    };

    const fetchChatHistory = async (sleep?: number) => {
        sleep && (await new Promise(resolve => setTimeout(resolve, sleep)));
        setLoadingHistory(true);
        if (token) {
            try {
                const response = await getChatHistoryOfUser(token);
                const filteredResponse = response?.filter(chat => getAgentName(chat.agent_id) !== undefined);
                setChatHistory(filteredResponse);
                setLoadingHistory(false);
                //DEBUG  console.log("HISTORY:", response);
            } catch (error) {
                console.error("Error fetching chat history:", error);
            }
        }
    };

    const readChatHistory = () => {
        return chatHistory;
    };

    const getChatconversation = async (sessionId: string, chatData: chatData, agentId: string, index: number) => {
        if (!token) {
            console.error("Token is missing");
            return;
        }
        try {
            setDeleting(index);
            const response = await getChatconversationBySessionId(token, sessionId);
            setDeleting(null);
            const conversationHistory: chatConversation[] = [];

            response?.forEach(conversation => {
                const deepCopyChoice = JSON.parse(JSON.stringify(chatData.choices));

                deepCopyChoice[0].message.content = conversation?.answer;
                if (conversation?.image) {
                    deepCopyChoice[0].message.image = conversation.image.split(",");
                } else {
                    deepCopyChoice[0].message.image = undefined;
                }
                deepCopyChoice[0].context.data_points = conversation?.context?.split("$$$");

                const chatAppResponse: ChatAppResponse = {
                    choices: deepCopyChoice
                };

                const chatAppResponses: ChatAppResponse[] = [chatAppResponse];
                const conversationData: chatConversation = {
                    query: conversation.query,
                    choices: chatAppResponses
                };
                conversationHistory.push(conversationData);
            });

            await handleAdd(chatColumns.length, conversationHistory, agentId, chatData.system_prompt_template, chatData.chatsession_id, chatData.thread_id);

            //DEBUG console.log("conversation:", response);
        } catch (error) {
            console.error("Error fetching chat conversation:", error);
        }
    };

    const getAgentName = (agentId: string): string | undefined => {
        return agents.find(agent => agent.id === agentId)?.name;
    };

    const getAgentIndex = (agentId: string): number => {
        return agents.findIndex(agent => agent.id === agentId) ?? 0;
    };

    useEffect(() => {
        initToken();
    }, []);

    useEffect(() => {
        if (token !== "") {
            fetchChatHistory();
        }
    }, [token]);

    const handleRemove = async (columnToRemove: number) => {
        if (chatColumns.length > 1) {
            const updatedColumns = chatColumns?.filter(column => column.id !== columnToRemove);
            setChatColumns(updatedColumns);
        }
    };

    const isMobile = () => {
        return width < 760;
    };

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    let currentIndex = 1;

    if (isMobile()) {
        return (
            // This is the mobile view
            <div ref={agentRootRef} className={[styles.containerMobile, "justify-content-around flex-row mx-0 px-0"].join(" ")}>
                <div ref={agentsRef} className={[styles.agentsContainer, "mx-0 px-0 row w-100"].join(" ")}>
                    <div className={[darkMode ? styles.lightText : styles.darkText, "row text-center dark"].join(" ")}>
                        {chatColumns?.length > 1 && (
                            <span>
                                Chat {index + 1} / {chatColumns.length}
                            </span>
                        )}
                    </div>
                    <Carousel
                        touch
                        interval={null}
                        controls={false}
                        activeIndex={index}
                        onSelect={handleSelect}
                        className={[styles.carouselMobile, !darkMode ? styles.carouselMobileLight : "", "d-lg-none h-100 ps-0 pe-0"].join(" ")}
                    >
                        {chatColumns.map((agent, index) => (
                            <Carousel.Item key={agent.id + "_item"} className="h-100">
                                <Chat
                                    key={agent.id + "_" + index}
                                    agent={agents}
                                    index={agent.number}
                                    add={() => handleAdd(index + 1)}
                                    remove={() => handleRemove(agent.id)}
                                    arrayIndex={index}
                                    agentRootRef={agentRootRef}
                                    agentCount={chatColumns.length}
                                    isActive={false}
                                    handleSetActiveAgent={() => console.log("active")}
                                    isMobile={true}
                                    conversation={chatColumns[index].history}
                                    systemPromt={chatColumns[index].systemPromt!}
                                    updateHistory={() => fetchChatHistory(1000)} // 5s delay for fetching chat history after adding a new chat
                                    sessionId={chatColumns[index].sessionId ?? ""}
                                    threadId={chatColumns[index].threadId ?? ""}
                                    userGroups={userGroups}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className=" h-100 d-flex overflow-hidden">
                <Sidebar
                    className={[darkMode ? styles.sidebar : styles.sidebarLight, styles.sidebarStyles].join(" ")}
                    collapsed={collapsed}
                    collapsedWidth="50px"
                >
                    <div className={styles.sidebarHeader}>
                        <div className="pt-2 ps-3 vh-40">
                            <span className={collapsed ? "d-none" : ""}>
                                <span>
                                    {t("chat.history.title")}{" "}
                                    <span className={[styles.spinner, loadingHistory ? "spinner-border" : ""].join(" ")} role="status"></span>
                                    <span className={loadingHistory ? "d-none" : ""}>{readChatHistory()?.length}</span> {t("chat.history.pcs")}
                                </span>
                            </span>
                            <span>&nbsp;</span>
                            <i className={["bi bi-list", styles.burger].join(" ")} onClick={() => setCollapsed(!collapsed)}></i>
                        </div>
                    </div>
                    <Menu className={[darkMode ? styles.sidebarMenu : styles.sidebarMenuLight, "mb-1"].join(" ")}>
                        {readChatHistory()?.map(
                            (history, index) =>
                                getAgentName(history.agent_id) && (
                                    <MenuItem
                                        disabled={deleting != null}
                                        key={index}
                                        className={[styles.menuItem, "mt-3", collapsed ? "d-none" : ""].join(" ")}
                                    >
                                        <span
                                            className="d-flex flex-row mt-2 justify-content-between pe-2"
                                            onClick={() => {
                                                getChatconversation(history.chatsession_id, history, history.agent_id, index);
                                            }}
                                        >
                                            {history?.topic != undefined ? history?.topic?.substring(0, 22) : history.query.substring(0, 19) + "..."}

                                            <i
                                                onClick={e => deleteHistory(e, history.chatsession_id, index, history.thread_id || null)}
                                                className={[styles.trash, deleting == null || deleting != index ? "bi bi-trash ms-2" : ""].join(" ")}
                                            />
                                            <div className={[styles.spinner, deleting == index ? "spinner-border" : "d-none"].join(" ")} role="status"></div>
                                        </span>
                                        <span className={styles.agentType}>{getAgentName(history.agent_id)}</span>
                                    </MenuItem>
                                )
                        )}
                    </Menu>
                </Sidebar>

                <div ref={agentRootRef} className={[styles.container, "justify-content-around flex-row mx-0 px-0"].join(" ")}>
                    <div ref={agentsRef} className={[styles.agentsContainer, "mx-0 px-0"].join(" ")}>
                        {chatColumns.map((agent, index) => (
                            <Chat
                                key={agent.id + "_" + index}
                                agent={agents}
                                index={agent.number}
                                add={() => handleAdd(index + 1)}
                                remove={() => handleRemove(agent.id)}
                                arrayIndex={index}
                                agentRootRef={agentRootRef}
                                agentCount={chatColumns.length}
                                isActive={false}
                                handleSetActiveAgent={() => console.log("active")}
                                isMobile={false}
                                conversation={chatColumns[index].history}
                                systemPromt={chatColumns[index].systemPromt!}
                                updateHistory={() => fetchChatHistory(1000)} // 1s delay for fetching chat history after adding a new chat
                                sessionId={chatColumns[index].sessionId ?? ""}
                                threadId={chatColumns[index].threadId ?? ""}
                                userGroups={userGroups}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Agents;
