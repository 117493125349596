import { AddCircle24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./Buttons.module.css";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const PlusButton = ({ className, disabled, onClick, darkMode }: Props) => {
    const t = useGlobalTranslation();
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext} style={{ left: "50px" }}>
                    {t("plusButton.zoom")}
                </span>
                <Button style={darkMode ? { color: "#fbfbfe" } : {}} icon={<AddCircle24Regular />} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
};
