import { useRef, useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Dropdown, IDropdownOption, Toggle, isDark } from "@fluentui/react";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";
import {
    chatApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    chatSecureApi,
    getChatHistoryOfUser,
    chatConversation,
    chatSQLApi,
    chatJiraApi
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { AgentDescription } from "../../components/AgentDescription/AgentDescription";
import { AddAgentButton } from "../../components/AddAgentButton/AddAgentButton";
import { RemoveAgentButton } from "../../components/RemoveAgentButton/RemoveAgentButton";
import { Agent } from "../../types/agent";
import { AgentDropdown } from "../../components/AgentDropdown/AgentDropdown";
import UserFilesModal from "../../components/UserFilesModal/UserFilesModal";
import { FilesField } from "../../components/FilesField/FilesField";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { useDarkMode } from "../../DarkModeContext";
import useWindowDimensions from "../../services/viewportService";
import { enSystemPrompt_chat, systemPrompt_chat, systemPrompt_documents, systemPrompt_jira } from "./Prompts";
import { AuthenticationResult } from "@azure/msal-browser";
import { v4 as uuidv4 } from "uuid";
import { currentLanguage, useGlobalTranslation } from "../../services/useGlobalTranslation";

type ChatColumnProps = {
    agent: Agent[];
    index: number;
    arrayIndex: number;
    agentCount: number;
    isActive: boolean;
    agentRootRef: React.MutableRefObject<HTMLDivElement | null>;
    add: () => void;
    remove: () => void;
    handleSetActiveAgent: () => void;
    isMobile: boolean;
    conversation: chatConversation[] | undefined;
    updateHistory: () => void;
    systemPromt: string;
    sessionId: string;
    threadId: string;
    userGroups: string[];
};

const Chat = (props: ChatColumnProps) => {
    const t = useGlobalTranslation();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [retrieveCount, setRetrieveCount] = useState<number>(30);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const userGroups = props.userGroups;

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const streamRef = useRef<HTMLDivElement | null>(null);
    const chatRootRef = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [allDataPoints, setAllDataPoints] = useState<string[]>([]);

    const [newIndex, setNewIndex] = useState<number>(props.index);
    const [selectedAgent, setSelectedAgent] = useState<Agent>(props.agent[newIndex]);
    const [selectedDescription, setSelectedDescription] = useState<string>(props.agent[newIndex]?.description);
    const [lockedFiles, setLockedFiles] = useState<string[]>([]);
    const [citationNumber, setCitationNumber] = useState<number>(0);
    const agents = props.agent;
    const [autoScroll, setAutoScroll] = useState<boolean>(true);
    const { darkMode, toggleDarkMode } = useDarkMode();
    const [isCompanyAdmin, setIsCompanyAdmin] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [systemPromptTemplate, setSystemPromptTemplate] = useState<string>("");
    const conversation = props.conversation;
    const [loadingHistory, setLoadingHistory] = useState<boolean>(true);
    const [threadId, setThreadId] = useState<string>(props.threadId);
    const [assistantImage, setAssistantImage] = useState<string[]>([]);

    const [togglePrivate, setTogglePrivate] = useState<boolean>(false);
    const { instance } = useMsal();

    const getDefaultPrompt = () => {
        const { systemPromt } = props;
        const { type } = selectedAgent;
        const isSystemPromptEmpty = !systemPromt;

        let prompt = "";

        if (isSystemPromptEmpty) {
            if (type === "Basic") {
                prompt = currentLanguage === "en-US" ? enSystemPrompt_chat : systemPrompt_chat;
            } else if (type === "Jira") {
                prompt = systemPrompt_jira;
            } else {
                prompt = systemPrompt_documents.toString();
            }
        } else {
            prompt = systemPromt;
        }

        setSystemPromptTemplate(prompt);
        return prompt;
    };

    useEffect(() => {
        if (answers.length == 0 && conversation?.length) {
            setLoadingHistory(true);
            const answersData: [string, ChatAppResponse][] = [];
            conversation.forEach((chat, index) => {
                answersData.push([chat.query, chat.choices[0]]);
                if (conversation.length - 1 === index) {
                    lastQuestionRef.current = chat.query;
                }
            });
            setAnswers(answersData); //
            setLoadingHistory(false);
        }
        setLoadingHistory(false);
    }, [conversation]);

    useEffect(() => {
        getDefaultPrompt();
    }, [selectedAgent]);
    const [idToken, setIdToken] = useState<AuthenticationResult | undefined>(undefined);
    const [chatSessionId, setChatSessionId] = useState<string>(props.sessionId ? props.sessionId : "");

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;
        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(
                    () => {
                        answer += newContent;
                        const latestResponse: ChatAppResponse = {
                            ...askResponse,
                            choices: [
                                {
                                    ...askResponse.choices[0],
                                    message: {
                                        content: answer,
                                        role: askResponse.choices[0].message.role,
                                        image: [...(askResponse.choices[0].message.image || [])]
                                    }
                                }
                            ]
                        };
                        setStreamedAnswers([...answers, [question, latestResponse]]);
                        resolve(null);
                    },
                    selectedAgent.model === "gpt-4o" ? 33 : 33
                );
            });
        };
        try {
            setIsStreaming(true);

            for await (const event of readNDJSONStream(responseBody)) {
                if (event["thread_id"]) {
                    setThreadId(event["thread_id"]);
                }
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }

                if (event["image"]) {
                    askResponse.choices[0].message.image = [...(askResponse.choices[0].message.image || []), event["image"]];
                }
            }
        } catch (e) {
            console.error("Error processing event:", e);
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [
                {
                    ...askResponse.choices[0],
                    message: { content: answer, role: askResponse.choices[0].message.role, image: askResponse.choices[0].message.image }
                }
            ]
        };
        // console.log("Full response:", fullResponse);
        return fullResponse;
    };

    const client = useLogin ? useMsal().instance : undefined;

    // get idToken if undefined
    useEffect(() => {
        if (useLogin && !idToken) {
            getToken(instance).then(token => setIdToken(token));
        }
    }, [useLogin, idToken, instance]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        const token = client ? await getToken(client) : undefined;
        setIdToken(token);

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: undefined,
                        system_prompt_template: systemPromptTemplate.length === 0 ? undefined : systemPromptTemplate,
                        exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        top: retrieveCount,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: useSuggestFollowupQuestions,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        search_index: selectedAgent.searchIndex,
                        locked_files: lockedFiles,
                        chatsession_id: chatSessionId,
                        temporary_chat: togglePrivate,
                        thread_id: threadId
                    }
                },
                agent_config: selectedAgent,
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            let response: Response | undefined = undefined;
            // NOTE: Agent type is checked and request is sent to the correct endpoint
            if (selectedAgent.type === "Basic") {
                response = await chatSecureApi(request, token?.accessToken);
            } else if (selectedAgent.type === "SQL") {
                response = await chatSQLApi(request, token?.accessToken);
            } else if (selectedAgent.type === "Jira") {
                response = await chatJiraApi(request, token?.accessToken);
            } else {
                response = await chatApi(request, token?.accessToken);
            }

            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
                setAnswers([...answers, [question, parsedResponse]]);

                if (answers?.length == 0 || answers?.length == conversation?.length) {
                    if (!togglePrivate) props.updateHistory();
                }
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
        setChatSessionId(uuidv4());
        setThreadId("");
    };

    const handleAddAgent = () => {
        props.add();
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    useEffect(() => {
        if (autoScroll) {
            streamRef.current?.scrollBy({ top: 1000, behavior: "smooth" });
        }
    }, [streamedAnswers, autoScroll]);

    const handleScroll = () => {
        if (streamRef.current && chatRootRef.current) {
            const { scrollTop: scrollYPosition, scrollHeight: scrollYHeight, clientHeight: chatRootHeight } = streamRef.current;
            const scrollFromBottom = scrollYHeight - scrollYPosition;
            const isBottomOfContainer = scrollFromBottom <= chatRootHeight + 20;

            setAutoScroll(isBottomOfContainer);
        }
    };

    const onSystemPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSystemPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const handleSelectedFiles = (selectedFiles: string[]) => {
        setLockedFiles(selectedFiles);
    };

    const handleRemoveFile = (fileName: string) => {
        setLockedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
    };

    const onShowCitation = (citation: string, index: number, citationNumber: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
            setCitationNumber(citationNumber - 1);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const handleSelectedAgentIndex = (selectedNameIndex: number) => {
        setNewIndex(selectedNameIndex);
        setSelectedAgent(props.agent[selectedNameIndex]);
        setSelectedDescription(props.agent[selectedNameIndex]?.description);
        clearChat();
    };

    useEffect(() => {
        const adminEmails = ["josua.kiviranta@innofactor.com", "satu.ryokkynen@innofactor.com"]; // TUNGE ISO KASA MAHIKSIA TÄNNE
        const account = client?.getActiveAccount();
        if (account) {
            setIsCompanyAdmin(adminEmails.includes(account.username.toLowerCase()));
        }
        setChatSessionId(chatSessionId == "" ? uuidv4() : chatSessionId);
    }, []);

    useEffect(() => {
        //DEBUG console.log("ANSWERS: ", answers);
    }, [answers]);

    const isMobile = () => {
        return width < 760;
    };

    const handleUploadComplete = useCallback(() => {
        setUploadComplete(true);
    }, []);

    const resetUploadCompleted = useCallback(() => {
        setUploadComplete(false);
    }, []);

    const haveOnlyOneAgent = () => {
        return props.agentCount === 1;
    };

    const getAllCitations = (answers: [string, ChatAppResponse][]) => {
        const dataPoints = answers.flatMap(answer => answer[1].choices[0].context.data_points);
        setAllDataPoints(dataPoints);
    };

    useEffect(() => {
        if (!isStreaming && !loadingHistory) {
            getAllCitations(answers);
        }
    }, [answers]);

    const toggleTemporaryChat = () => {
        setTogglePrivate(!togglePrivate);
    };

    const getAnswerForAnalysisPanel = (answers: [string, ChatAppResponse][], selectedAnswer: number) => {
        const answerData = answers[selectedAnswer][1];
        if ((answerData.choices[0].context.data_points as string[] | string) != "No data points") {
            return answerData;
        }
        let i = selectedAnswer - 1;
        while (i >= 0) {
            const previousAnswer = answers[i][1];
            if (previousAnswer.choices[0].context.data_points != undefined) {
                answerData.choices[0].context.data_points = previousAnswer.choices[0].context.data_points;
                return answerData;
            }
            i--;
        }
        answerData.choices[0].context.data_points = [];
        return answerData;
    };

    return (
        <div ref={chatRootRef} className={darkMode ? styles.containerDark : styles.container}>
            <div className={styles.chatRoot}>
                <div
                    className={[isMobile() ? styles.chatContainerMobile : styles.chatContainer, haveOnlyOneAgent() && !isMobile() ? styles.fullWidth : ""].join(
                        " "
                    )}
                >
                    <div className={`${darkMode ? styles.commandsContainerDark : styles.commandsContainer} ${props.isActive ? "active" : ""}`}>
                        <div className={styles.dropdownContainer}>
                            <AgentDropdown
                                darkMode={darkMode}
                                default={selectedAgent.name}
                                options={agents}
                                onSelect={handleSelectedAgentIndex}
                                userGroups={userGroups}
                            />
                            {selectedAgent.type === "FileReader" && (
                                <>
                                    <FileUpload darkMode={darkMode} isActive={true} agentType={selectedAgent.type} OnUploadComplete={handleUploadComplete} />
                                    <UserFilesModal
                                        darkMode={darkMode}
                                        onSelect={handleSelectedFiles}
                                        lockedFiles={lockedFiles}
                                        isActive={false}
                                        agentType={selectedAgent.type}
                                        uploadComplete={uploadComplete}
                                        resetUploadCompleted={resetUploadCompleted}
                                    />
                                </>
                            )}
                            {selectedAgent.type === "Azure" && isCompanyAdmin && (
                                <>
                                    <FileUpload darkMode={darkMode} isActive={true} agentType={selectedAgent.type} OnUploadComplete={handleUploadComplete} />
                                    <UserFilesModal
                                        darkMode={darkMode}
                                        onSelect={handleSelectedFiles}
                                        lockedFiles={lockedFiles}
                                        isActive={false}
                                        agentType={selectedAgent.type}
                                        uploadComplete={uploadComplete}
                                        resetUploadCompleted={resetUploadCompleted}
                                    />
                                </>
                            )}

                            {selectedAgent.type === "Basic" && (
                                <div className={[darkMode ? "text-light" : "", "d-flex ms-3"].join(" ")}>
                                    {t("chatSettings.temporaryChat")}
                                    <Toggle checked={togglePrivate} onChange={toggleTemporaryChat} className="mb-0 ms-2 mt-1" />
                                </div>
                            )}
                        </div>
                        <div className={styles.buttonsContainer}>
                            <ClearChatButton
                                className={styles.commandButton}
                                onClick={clearChat}
                                disabled={!lastQuestionRef.current || isLoading}
                                darkMode={darkMode}
                            ></ClearChatButton>
                            <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} darkMode={darkMode} />
                            <AddAgentButton className={styles.commandButton} onClick={handleAddAgent} darkMode={darkMode} />
                            <RemoveAgentButton className={styles.commandButton} onClick={props.remove} darkMode={darkMode} />
                        </div>
                    </div>
                    {lockedFiles.length > 0 && selectedAgent.type === "FileReader" && (
                        <FilesField onRemove={handleRemoveFile} fileNames={lockedFiles}></FilesField>
                    )}
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <AgentDescription
                                darkMode={darkMode}
                                agentName={selectedAgent.name}
                                isMobile={props.isMobile}
                                agentDescription={selectedDescription + (togglePrivate ? t("chatSettings.temporaryChatDescription") : "")}
                            />
                        </div>
                    ) : (
                        <div
                            onScroll={handleScroll}
                            className={
                                darkMode
                                    ? isMobile()
                                        ? styles.chatMessageStreamDarkMobile
                                        : styles.chatMessageStreamDark
                                    : isMobile()
                                    ? styles.chatMessageStreamMobile
                                    : styles.chatMessageStream
                            }
                            ref={streamRef}
                        >
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index}>
                                        <UserChatMessage darkMode={darkMode} message={streamedAnswer[0]} />
                                        <div className={isMobile() ? styles.chatMessageGptMobile : styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                key={index}
                                                answer={streamedAnswer[1]}
                                                isSelected={false}
                                                onCitationClicked={(c, citationNumber) => onShowCitation(c, index, citationNumber)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                agentType={selectedAgent.type}
                                                darkMode={darkMode}
                                                idToken={idToken}
                                                chatSessionId={chatSessionId}
                                                isPrivate={togglePrivate}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage darkMode={darkMode} message={answer[0]} />
                                        <div className={isMobile() ? styles.chatMessageGptMobile : styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={(c, citationNumber) => onShowCitation(c, index, citationNumber)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                agentType={selectedAgent.type}
                                                darkMode={darkMode}
                                                idToken={idToken}
                                                chatSessionId={chatSessionId}
                                                isPrivate={togglePrivate}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage darkMode={darkMode} message={lastQuestionRef.current} />
                                    <div className={isMobile() ? styles.chatMessageGptMinWidthMobile : styles.chatMessageGptMinWidth}>
                                        <AnswerLoading darkMode={darkMode} />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage darkMode={darkMode} message={lastQuestionRef.current} />
                                    <div className={isMobile() ? styles.chatMessageGptMinWidthMobile : styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={[darkMode ? styles.chatInputDark : styles.chatInput, props.isMobile ? styles.mobileStyles : ""].join(" ")}>
                        <QuestionInput
                            clearOnSend
                            darkMode={darkMode}
                            placeholder={t("questionInput.placeholder")}
                            disabled={isLoading}
                            isStreaming={isStreaming}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        allCitations={allDataPoints}
                        className={isMobile() ? styles.chatAnalysisPanelMobile : styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={getAnswerForAnalysisPanel(answers, selectedAnswer)}
                        activeTab={activeAnalysisPanelTab}
                        citationNumber={citationNumber}
                        agent={selectedAgent}
                        darkMode={darkMode}
                        client={client}
                    />
                )}
                <Panel
                    styles={{
                        main: { backgroundColor: `${darkMode ? "#26262f" : "white"}`, color: `${darkMode ? "#fbfbfe" : ""}` },
                        header: { backgroundColor: `${darkMode ? "#26262f" : "white"}` },
                        headerText: { color: `${darkMode ? "#fbfbfe" : ""}` },
                        commands: { backgroundColor: `${darkMode ? "#26262f" : "white"}` },
                        footer: { backgroundColor: `${darkMode ? "#26262f" : "white"}` }
                    }}
                    headerText={t("chatSettings.header")}
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel={t("chatSettings.close")}
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        styles={{ subComponentStyles: { label: { root: { color: `${darkMode ? "#fbfbfe" : ""}` } } } }}
                        className={styles.chatSettingsSeparator}
                        defaultValue={systemPromptTemplate != "" ? systemPromptTemplate : getDefaultPrompt()}
                        label={t("chatSettings.overridePrompt")}
                        multiline
                        autoAdjustHeight
                        onChange={onSystemPromptTemplateChange}
                    />

                    {/* <SpinButton
                        styles={{ label: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        label={t("chatSettings.retriveResultsTitle")}
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField
                        styles={{ subComponentStyles: { label: { root: { color: `${darkMode ? "#fbfbfe" : ""}` } } } }}
                        className={styles.chatSettingsSeparator}
                        label={t("chatSettings.excludeCategory")}
                        onChange={onExcludeCategoryChanged}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label={t("chatSettings.sematicRankerTitle")}
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label={t("chatSettings.queryContextualTitle")}
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label={t("chatSettings.suggestFollowUpTitle")}
                        onChange={onUseSuggestFollowupQuestionsChange}
                    /> */}
                    {/* {useLogin && (
                        <Checkbox
                            styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter}
                            label={t("chatSettings.useOidSecurityFilter")}
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin && (
                        <Checkbox
                            styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter}
                            label={t("chatSettings.useGroupSecurityFilter")}
                            disabled={!client?.getActiveAccount()}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}
                    <Dropdown
                        styles={{ label: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            {
                                key: "hybrid",
                                text: t("chatSettings.typeVectorAndText"),
                                selected: retrievalMode == RetrievalMode.Hybrid,
                                data: RetrievalMode.Hybrid
                            },
                            {
                                key: "vectors",
                                text: t("chatSettings.typeVector"),
                                selected: retrievalMode == RetrievalMode.Vectors,
                                data: RetrievalMode.Vectors
                            },
                            { key: "text", text: t("chatSettings.typeText"), selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    /> */}
                    {/* <Checkbox
                        styles={{ text: { color: `${darkMode ? "#fbfbfe" : ""}` } }}
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label={t("chatSettings.streamChatTitle")}
                        onChange={onShouldStreamChange}
                    /> */}
                    {/*useLogin && <TokenClaimsDisplay darkMode={darkMode} />*/}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
