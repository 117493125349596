import { useMemo, useRef, useState, useEffect } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import copy from "clipboard-copy";
import { AuthenticationResult } from "@azure/msal-browser";
import FeedbackModal from "../FeedbackModal/FeedbackModal";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, citationNumber: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    agentType?: string;
    darkMode: boolean;
    chatSessionId?: string;
    idToken?: AuthenticationResult | undefined;
    isPrivate?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    agentType,
    darkMode,
    chatSessionId,
    idToken,
    isPrivate
}: Props) => {
    const t = useGlobalTranslation();
    const renderAsHTML = false;

    const followupQuestions = answer.choices[0]?.context.followup_questions;
    const messageContent = answer.choices[0]?.message?.content;
    const assistantImage = answer.choices[0]?.message?.image;
    const [expandedImageIndex, setExpandedImageIndex] = useState<number[]>([]);

    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(messageContent, isStreaming, agentType, onCitationClicked, renderAsHTML, darkMode),
        [answer, darkMode, renderAsHTML]
    );

    const textRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleCopy = () => {
        if (textRef.current) {
            copy(textRef.current.innerText);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };

    const handleImageClick = (index: number) => {
        if (expandedImageIndex.includes(index)) {
            setExpandedImageIndex(expandedImageIndex.filter(i => i !== index)); // Remove from expanded array (collapse)
        } else {
            setExpandedImageIndex([...expandedImageIndex, index]); // Add to expanded array (expand)
        }
    };

    return (
        <Stack className={`${darkMode ? styles.answerContainerDark : styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <div className={darkMode ? styles.answerIconDark : styles.answerIcon}>{t("answer.ai")}</div>
                    <div className={styles.copyTextContainer}>
                        {isCopied && <div className={darkMode ? styles.copyTextDark : styles.copyText}>{t("answer.copied")}</div>}
                        <IconButton
                            styles={{
                                root: { color: `${darkMode ? "#FBFBFE" : "black"}` },
                                rootHovered: { backgroundColor: `${darkMode ? "#343541" : "#1F364E"}`, color: `${darkMode ? "#FBFBFE" : "#F8F8F5"}` },
                                rootPressed: { backgroundColor: `${darkMode ? "#26262f" : "#1F364E"}`, color: `${darkMode ? "#FBFBFE" : "#F8F8F5"}` }
                            }}
                            iconProps={{ iconName: "Copy" }}
                            title="Copy"
                            ariaLabel="Copy"
                            onClick={handleCopy}
                        />
                    </div>
                </Stack>
            </Stack.Item>
            <Stack.Item grow>
                <div ref={textRef}>
                    <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                        {parsedAnswer.answerHtml}
                    </Markdown>
                    {assistantImage &&
                        assistantImage.length > 0 &&
                        assistantImage.map((image, index) => (
                            <img
                                //style={{
                                //    width: expandedImageIndex.includes(index) ? "1000px" : "50px",
                                //    height: "auto",
                                //    cursor: "pointer",
                                //    transition: " 0.3s ease-in-out" // Smooth transition for width
                                //}}
                                key={index}
                                className="d-flex mw-100 mb-4"
                                src={"data:image/png;base64," + image}
                                alt={`assistant image ${index}`}
                            />
                        ))}
                </div>
            </Stack.Item>

            {agentType !== "Basic" && !!parsedAnswer.citations.length && (
                <>
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>{t("answer.citation")}</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => (!isStreaming ? onCitationClicked(path, i) : "")}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </>
            )}
            {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>{t("answer.followUpQuestion")}</span>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            {!isPrivate && <FeedbackModal chatSessionId={chatSessionId} idToken={idToken} darkMode={darkMode} />}
        </Stack>
    );
};
