import { IconButton } from "@fluentui/react";
import styles from "./UserChatMessage.module.css";
import { useRef, useState } from "react";
import copy from "clipboard-copy";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface Props {
    message: string;
    darkMode: boolean;
}

export const UserChatMessage = ({ message, darkMode }: Props) => {
    const t = useGlobalTranslation();
    const textRef = useRef<HTMLDivElement>(null);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleCopy = () => {
        if (textRef.current) {
            copy(textRef.current.innerText);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    };
    return (
        <div className={styles.container}>
            <div className={darkMode ? styles.messageAndCopyDark : styles.messageAndCopy}>
                <div ref={textRef} className={styles.message}>
                    {message}
                </div>
                <div className={styles.copyTextContainer}>
                    {isCopied && <div className={darkMode ? styles.copyTextDark : styles.copyText}>{t("userChatMessage.copied")}</div>}
                    <IconButton
                        styles={{
                            root: { color: `${darkMode ? "#FBFBFE" : "black"}` },
                            rootHovered: { backgroundColor: `${darkMode ? "#26262f" : "#1F364E"}`, color: `${darkMode ? "#FBFBFE" : "#F8F8F5"}` },
                            rootPressed: { backgroundColor: `${darkMode ? "#26262f" : "#1F364E"}`, color: `${darkMode ? "#FBFBFE" : "#F8F8F5"}` }
                        }}
                        iconProps={{ iconName: "Copy" }}
                        title="Copy"
                        ariaLabel="Copy"
                        onClick={handleCopy}
                    />
                </div>
            </div>
        </div>
    );
};
