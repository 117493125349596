import { SubtractCircle24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./RemoveAgentButton.module.css";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    darkMode: boolean;
}

export const RemoveAgentButton = ({ className, disabled, onClick, darkMode }: Props) => {
    const t = useGlobalTranslation();
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <div className={darkMode ? styles.tooltipDark : styles.tooltip}>
                <span className={darkMode ? styles.tooltiptextDark : styles.tooltiptext}>{t("removeAgentButton.remove")}</span>
                <Button style={darkMode ? { color: "#fbfbfe" } : {}} icon={<SubtractCircle24Regular />} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
};
