import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styles from "./Answer.module.css";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

function processCitations(
    part: string,
    citations: string[],
    agentType: string | undefined,
    onCitationClicked: (citationFilePath: string, citationNumber: number) => void
): string {
    const citationParts = part.split(/\[([^\]]+)\]/g);

    //is codeblock
    if (part.startsWith("```")) {
        return part;
    }

    return citationParts
        .map((citationPart, citationIndex) => {
            if (citationIndex % 2 === 0 || !citationPart.toLowerCase().includes("pdf")) {
                return citationPart;
            } else {
                let citationIndex: number;
                if (citations.indexOf(citationPart) !== -1) {
                    citationIndex = citations.indexOf(citationPart) + 1;
                } else {
                    citations.push(citationPart);
                    citationIndex = citations.length;
                }

                if (agentType !== "Basic") {
                    const path = getCitationFilePath(citationPart);
                    return renderToStaticMarkup(
                        <a className="supContainer" title={citationPart} onClick={() => onCitationClicked(path!, citationIndex)}>
                            <sup>{citationIndex}</sup>
                        </a>
                    );
                } else {
                    return "";
                }
            }
        })
        .join("");
}

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    agentType: string | undefined,
    onCitationClicked: (citationFilePath: string, citationNumber: number) => void,
    renderAsHTML: boolean,
    darkMode: boolean
): HtmlParsedAnswer {
    const citations: string[] = [];

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer?.trim();
    // Omit a citation that is still being typed during streaming
    if (isStreaming && agentType !== "Basic") {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const codeBlockRegex = /(```[\s\S]*?```)/g;
    const parts = parsedAnswer.split(codeBlockRegex) as string[];

    const fragments: string[] = (parts || [])?.filter(Boolean)?.map((part, index) => {
        const citation = processCitations(part, citations, agentType, onCitationClicked);
        return citation;
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
}
