import { motion } from "framer-motion";
import styles from "./Landingpage.module.css";
import Pic1 from "./woman-with-coffee.png";
import Pic2 from "./symphony-10.png";
import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logo-white.svg";
import { useMsal } from "@azure/msal-react";
import { getRedirectUri, loginRequest } from "../../authConfig";
import { useEffect, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { useDarkMode } from "../../DarkModeContext";
import { ThemeToggler } from "../../components/ThemeToggler";
import useWindowDimensions from "../../services/viewportService";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface LandingpageProps {
    onActiveAccountChange: (newActiveAccount: AccountInfo | null) => void;
}

export function Landingpage(props: LandingpageProps): JSX.Element {
    const t = useGlobalTranslation();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { darkMode, toggleDarkMode } = useDarkMode();
    const logoutText = `Logout\n${activeAccount?.username}`;
    const { width } = useWindowDimensions();
    const handleLogin = async () => {
        try {
            await instance.loginPopup({
                ...loginRequest,
                redirectUri: getRedirectUri()
            });
        } catch (error) {
            console.error("Login failed:", error);
        }
    };
    const handleLogout = async () => {
        try {
            await instance.logoutPopup({
                mainWindowRedirectUri: "", // redirects the top level app after logout
                account: instance.getActiveAccount()
            });
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        // Call the callback function whenever activeAccount changes
        props.onActiveAccountChange(activeAccount);
    }, [activeAccount, props.onActiveAccountChange]);

    const isMobile = () => {
        return width < 500;
    };

    return (
        <>
            <div className={styles.logoContainer}>
                <img src={darkMode ? logoWhite : logo} alt="Innofactor logo" className={styles.logo} />
            </div>
            <button onClick={activeAccount ? handleLogout : handleLogin} className={darkMode ? styles.headerLoginDark : styles.headerLogin}>
                {activeAccount ? logoutText : "Kirjaudu sisään"}
            </button>

            <div className={styles.container}>
                <div className={`${styles.slide} ${darkMode ? styles.slide1Dark : styles.slide1}`}>
                    <div className={[styles.content, "p-1"].join(" ")}>
                        <div className={darkMode ? styles.rowDark : styles.row}>
                            <div>
                                <motion.img
                                    initial={{
                                        scale: 0
                                    }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 0.5,
                                        type: "tween",
                                        bounce: 0.1
                                    }}
                                    src={Pic1}
                                    width="100%"
                                    className={styles.pic1}
                                />
                            </div>

                            <div className={styles.blueBg}>
                                <motion.p
                                    initial={{
                                        scale: 0
                                    }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 0.75,
                                        type: "tween",
                                        bounce: 0.1
                                    }}
                                    className={isMobile() ? styles.largeMobile : styles.large}
                                >
                                    {t("landingpage.header1")}
                                </motion.p>
                            </div>
                        </div>
                        <div className={darkMode ? styles.rowDark : styles.row}>
                            <div>
                                <motion.p
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 1,
                                        type: "tween",
                                        bounce: 0.1
                                    }}
                                    className={isMobile() ? styles.smallMobile : styles.small}
                                >
                                    {t("landingpage.header2")}
                                    <em>{t("landingpage.header3")}</em>
                                    {t("landingpage.header4")}
                                </motion.p>
                            </div>
                            <div>
                                <motion.img
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: 1.25,
                                        type: "tween",
                                        bounce: 0.1
                                    }}
                                    src={Pic2}
                                    width="100%"
                                    className={styles.pic2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
