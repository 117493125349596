import { useState, FormEvent, useRef } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { ArrowCircleRight24Filled, Send28Filled } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";
import { useGlobalTranslation } from "../../services/useGlobalTranslation";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    darkMode: boolean;
    isStreaming: boolean;
}

export const QuestionInput = ({ onSend, disabled, isStreaming, placeholder, clearOnSend, darkMode }: Props) => {
    const t = useGlobalTranslation();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            resetTextareaHeight(textAreaRef.current!);
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
            resetTextareaHeight(ev.currentTarget);
        }
    };

    const onQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        updateTextAreaHeight(e.currentTarget);
        setQuestion(e.currentTarget.value);
    };

    const updateTextAreaHeight = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = "32px";
        textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    };

    const resetTextareaHeight = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = "32px";
    };

    const sendQuestionDisabled = disabled || !question.trim();

    {
        darkMode ? styles.loginButtonDark : styles.loginButton;
    }
    return (
        <div className={darkMode ? styles.questionInputContainerDark : styles.questionInputContainer}>
            <form className={styles.questionFormContainer}>
                <textarea
                    className={darkMode ? styles.questionInputTextAreaDark : styles.questionInputTextArea}
                    value={question}
                    placeholder={isStreaming || disabled ? t("questionInput.loading") : t("questionInput.placeholder")}
                    rows={1}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    ref={textAreaRef}
                    disabled={isStreaming || disabled}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <Button
                        size="large"
                        icon={<ArrowCircleRight24Filled primaryFill={darkMode ? "#ffff" : "#1f364e"} />}
                        disabled={sendQuestionDisabled}
                        onClick={sendQuestion}
                    />
                </div>
            </form>
        </div>
    );
};
